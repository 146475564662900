import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'
import htmlTemplate from './serve-layout.html'
import { loader } from './loader.js'
import pkg from '../package.json'

// Добавление версии стилям для обхода кэша
// В исходники попадет только версия из package.json
const layout = htmlTemplate.replace(/<%=version%>/gm, pkg.version)
const routes = constructRoutes(layout)
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return Promise.resolve()
      .then(() => {
        loader()
        if (!!~name.indexOf('@vite')) {
          // Если это приложение vite
          return import(/* webpackIgnore: true */ System.resolve(name))
        } else {
          return System.import(name)
        }
      })
      .then((app) => {
        return app
      })
  },
})
function showUpdateModal(version) {
  const modal = document.createElement('div')
  modal.style.position = 'fixed'
  modal.style.top = '0'
  modal.style.left = '0'
  modal.style.width = '100vw'
  modal.style.height = '100vh'
  modal.style.background = 'rgba(0, 0, 0, 0.1)'
  modal.style.display = 'flex'
  modal.style.alignItems = 'center'
  modal.style.justifyContent = 'center'
  modal.style.zIndex = '1000000000'

  modal.innerHTML = `
    <div style="background: white; padding: 20px; border-radius: 8px; text-align: center;">
      <h3>Доступна новая версия</h3>
      <p>Перезагрузите страницу, чтобы обновить приложение до версии ${version}.</p>
      <button id="spa-update-button" style="margin-top: 20px; padding: 10px 20px; font-size: 16px;">Обновить</button>
      <button id="spa-cancel-button" style="margin-top: 20px; padding: 10px 20px; font-size: 16px;">Не сейчас</button>
    </div>
  `

  document.body.appendChild(modal)

  // Слушатель для кнопки обновления
  document.getElementById('spa-update-button').addEventListener('click', () => {
    window.location.reload(true) // Перезагрузка с очисткой кэша
  })
  document
    .getElementById('spa-cancel-button')
    .addEventListener('click', (event) => {
      modal.remove()
    })
}
const layoutEngine = constructLayoutEngine({ routes, applications })
layoutEngine.version = pkg.version
layoutEngine.isActive()
applications.forEach(registerApplication)
const curVersion = async () => {
  const response = await fetch('/package.json')
  const version = await response.json()
  return version
}
window.addEventListener('popstate', (event) => {
  // eslint-disable-next-line no-console
  console.log('Fiered event "Popstate":', { ...event })
  fetch('/package.json')
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      // eslint-disable-next-line no-console
      console.log('App current version: ', {
        currentVersion: data?.version,
        runnedVersion: pkg.version,
      })
      if (pkg.version != data.version) {
        showUpdateModal(data.version)
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.warning('Error get current version: ', { ...err })
    })
    .finally(() => {
      // eslint-disable-next-line no-console
      console.log('Check release finalize')
    })
})

start()
